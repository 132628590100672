import {
  GET_ACTIVE_USERS_FAILURE,
  GET_ACTIVE_USERS_REQUEST,
  GET_ACTIVE_USERS_RESPONSE,
  GET_MESSAGE_SETTING_FAILURE,
  GET_MESSAGE_SETTING_REQUEST,
  GET_MESSAGE_SETTING_RESPONSE,
  GET_REPORTS_REQUEST,
  GET_REPORTS_RESPONSE,
  GET_SETTING_FAILURE,
  GET_SETTING_REQUEST,
  GET_SETTING_RESPONSE,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_RESPONSE,
  REGISTER_ACTIVE_USERS_FAILURE,
  REGISTER_ACTIVE_USERS_REQUEST,
  REGISTER_ACTIVE_USERS_RESPONSE,
  UPDATE_ACTIVE_USERS_FAILURE,
  UPDATE_ACTIVE_USERS_REQUEST,
  UPDATE_ACTIVE_USERS_RESPONSE,
  UPDATE_MESSAGE_SETTING_REQUEST,
  UPDATE_MESSAGE_SETTING_RESPONSE,
  UPDATE_SETTING_REQUEST,
  UPDATE_SETTING_RESPONSE,
} from "./actions";
const today = new Date();
// Initial State
const settingsInitialState = {
  isLoading: false,
  error: null,
  items: [],
  activeUsers: {
    items: [],
  },
  settings: {
    items: [],
  },
  reports: {
    items: [],
  },
  updateSuccess: null,
  passwordResetStatus: null,
  passwordResetError: null,
};

// State
export default (state = settingsInitialState, action) => {
  switch (action.type) {
    case GET_MESSAGE_SETTING_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_MESSAGE_SETTING_RESPONSE:
      return {
        ...state,
        items: action.response,
        error: null,
        isLoading: false,
      };
    case GET_MESSAGE_SETTING_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_SETTING_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_SETTING_RESPONSE:
      return {
        ...state,
        settings: {
          items: action.response,
        },
        error: null,
        isLoading: false,
      };

    case GET_REPORTS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_REPORTS_RESPONSE:
      return {
        ...state,
        reports: {
          items: action.response,
        },
        error: null,
        isLoading: false,
      };
    case GET_SETTING_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPDATE_MESSAGE_SETTING_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_MESSAGE_SETTING_RESPONSE:
      return {
        ...state,
        items: state.items.map((setting) => {
          if (setting.id == action.response.id) {
            return action.response;
          }
          return setting;
        }),
        error: null,
        isLoading: false,
        updateSuccess: true,
      };
    case UPDATE_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPDATE_SETTING_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_SETTING_RESPONSE:
      return {
        ...state,
        settings: {
          items: state.settings.items.map((setting) => {
            if (setting.uuid == action.response.uuid) {
              return action.response;
            }
            return setting;
          }),
        },
        error: null,
        isLoading: false,
        updateSuccess: true,
      };
    case GET_ACTIVE_USERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_ACTIVE_USERS_RESPONSE:
      return {
        ...state,
        activeUsers: {
          items: action.response,
        },
        error: null,
        isLoading: false,
      };
    case GET_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPDATE_ACTIVE_USERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_ACTIVE_USERS_RESPONSE:
      return {
        ...state,
        activeUsers: {
          items: state.activeUsers.items.map((setting) => {
          if (setting.uuid == action.response[0].uuid) {
            return action.response[0];
          }
          return setting;
          
        })
        },
        error: null,
        isLoading: false,
        updateSuccess: true,
      };
    case UPDATE_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case REGISTER_ACTIVE_USERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case REGISTER_ACTIVE_USERS_RESPONSE:
      return {
        ...state,
        items: state.items.map((setting) => {
          if (setting.id == action.response.id) {
            return action.response;
          }
          return setting;
        }),
        error: null,
        isLoading: false,
        updateSuccess: true,
      };
    case REGISTER_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        error: null,
        passwordResetError: null,
        passwordResetStatus: null,
        isLoading: action.isLoading,
      };
    case PASSWORD_RESET_RESPONSE:
      return {
        ...state,
        error: null,
        isLoading: false,
        passwordResetStatus: true,
        passwordResetError: null,
      };
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        passwordResetStatus: null,
        passwordResetError: true,
      };

    default:
      return {
        ...state,
      };
  }
};
