import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSetting } from "../api/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

function GeneralSettings(props) {
  const classes = useStyles();

  const settings = props.setting.settings.items;
  const isAdmin = !props.user.details.isSuperUser;

  //Create Var for each setting
  let lateFees, lateFeeAmount, setupFee, dailyProcess, developerContact;

  //Loop through settings and set state
  for (let setting of settings) {
    if (setting.name == "lateFees") {
      lateFees = setting;
    }
    if (setting.name == "lateFeeAmount") {
      lateFeeAmount = setting;
    }
    if (setting.name == "setupFee") {
      setupFee = setting;
    }
    if (setting.name == "dailyProcess") {
      dailyProcess = setting;
    }
    if (setting.name == "developerContact") {
      developerContact = setting;
    }
  }

  const [state, setState] = useState({
    lateFees: lateFees,
    setupFee: setupFee,
    lateFeeAmount: lateFeeAmount,
    dailyProcess: dailyProcess,
    developerContact: developerContact,
  });

  //Dynamic change event to handle all inputs
  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.type == "checkbox") value = evt.target.checked;

    const newState = {
      ...state,
      [evt.target.name]: {
        ...state[evt.target.name],
        value: value,
      },
    };
    setState(newState);
  };

  const handleUpdate = async (evt) => {
    try {
      let response = await props.updateSetting(evt.target.id, {
        value: state[evt.target.name].value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {props.setting.isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          disabled={props.setting.isLoading || isAdmin}
          size="small"
          variant="contained"
          color="secondary"
          onClick={handleUpdate}
        >
          Save
        </Button>
      )}
      <div className={classes.root}>
        {props.setting.settings.items.length > 0 && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  name="lateFees"
                  onChange={handleChange}
                  disabled={props.setting.isLoading || isAdmin}
                  id={state.lateFees.uuid}
                  onBlur={handleUpdate}
                  checked={state.lateFees.value == 0 ? false : true}
                />
              }
              label="Late Fees"
            />
            <FormControlLabel
              control={
                <Switch
                  name="dailyProcess"
                  onChange={handleChange}
                  disabled={props.setting.isLoading || isAdmin}
                  id={state.dailyProcess.uuid}
                  onBlur={handleUpdate}
                  checked={state.dailyProcess.value == 0 ? false : true}
                />
              }
              label="Daily Process"
            />
            <TextField
              id={`${state.developerContact.uuid}`}
              label="Developer Contact Email"
              disabled={props.setting.isLoading || isAdmin}
              style={{ margin: 8 }}
              helperText={`Last Updated: ${moment(
                state.developerContact.updated
              ).format("L LT")}`}
              placeholder="Developer Contact"
              fullWidth
              name="developerContact"
              onChange={handleChange}
              onBlur={handleUpdate}
              value={state.developerContact.value}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Late Fee %"
              variant="standard"
              value={state.lateFeeAmount.value}
              name="lateFeeAmount"
              type="number"
              helperText={`Last Updated: ${moment(
                state.lateFeeAmount.updated
              ).format("L LT")}`}
              onChange={handleChange}
              onBlur={handleUpdate}
              disabled={props.setting.isLoading || isAdmin}
              id={`${state.lateFeeAmount.uuid}`}
              placeholder="Late Fee"
              style={{ margin: 8 }}
            />
            <TextField
              label="Setup Fee"
              variant="standard"
              value={state.setupFee.value}
              name="setupFee"
              type="number"
              helperText={`Last Updated: ${moment(
                state.setupFee.updated
              ).format("L LT")}`}
              onChange={handleChange}
              onBlur={handleUpdate}
              disabled={props.setting.isLoading || isAdmin}
              id={`${state.setupFee.uuid}`}
              placeholder="Setup Fee"
              style={{ margin: 8 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
GeneralSettings.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function GeneralSettingsState(state) {
  return {
    setting: state.setting,
    user: state.user,
  };
}

export default connect(GeneralSettingsState, { updateSetting })(
  GeneralSettings
);
