// Helpers
import moment from "moment";

// Render element or component by provided condition
export function renderIf(condition, renderFn) {
  return condition ? renderFn() : null;
}

export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

// Substring with ...
export function subString(string, length = 0) {
  return string.length > length ? `${string.substr(0, length)}...` : string;
}

export function dateFormatter(dateStr) {
  if(moment(dateStr).format("YYYY-MM-DD") == "Invalid date" || dateStr == null) {
    return ""
  }
  return moment(dateStr).format("YYYY-MM-DD");
}


export function dateFormatterNoTime(dateStr) {
  if(moment(dateStr).utc().format("YYYY-MM-DD") == "Invalid Date" || dateStr == null) {
    return ""
  }
  return moment(dateStr).utc().format("YYYY-MM-DD");
}

export function dateTime(date) {
  return moment(date).format("L");
}

export function dateTimeLocal(date) {
  return moment(date).format("lll");
}

export function dateTimeMediumLength(date) {
  return moment(date).format("L");
}

export function txtBoxDateFormatter(date) {
   // check if string
   if (typeof date === "string" || date instanceof String) {
    date = date.split("T")[0];
  }
  return moment(date).format('YYYY-MM-DD')

}

export function formatPhoneNumber(phoneNumberString) {
  if (phoneNumberString && phoneNumberString != null) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
  return null;
}

export function getCurrentDay_MonthDay() {
  //  Convert a string like '2020-10-04T00:00:00' into '4/Oct/2020'
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let currentdate = new Date();
  let toReturn = months[currentdate.getMonth()] + " " + currentdate.getDate();
  return toReturn;
}

export function dueDateSortFunction(a, b) {
  var dateA = new Date(a.dueDate.toString()).getTime();
  var dateB = new Date(b.dueDate.toString()).getTime();
  return dateA > dateB ? 1 : -1;
}

// This helper method returns true or false if the total is negative. Used to filter bills at checkout
export function billIsStillValid(bill) {
  return bill.total - bill.paymentsCredits > 0;
}

// This helper method returns true or false if the total is negative. Used to filter bills at checkout
export function getBillValue(bill) {
  const result = bill.total - bill.paymentsCredits;
  if (result > 0) {
    return result;
  }
  return 0;
}

/*
// Another helper method to centralize totaling processes that are at this time uncertain
export function getBalanceDueSum(bills){
  var curentBalance = 0.00;
  if (this.props.bills.data.length > 1)
  {
      this.props.bills.data.sort(dueDateSortFunction);
      if (this.props.bills.data[0].usage < this.props.bills.data[1].usage){
          improvingConsumption = true;
      }
  }
  return 0;
}
 
// Another helper method to centralize totaling processes that are at this time uncertain
export function getCurrentAvailableCreditsSum(bill){
  const result = bill.total - bill.paymentsCredits;
    if (result > 0)
    { 
      return result;
    }
    return 0;
}*/

// Expects a string like  '4/10/2020'
export function isLate(dateStr) {
  dateStr = dateStr.split("/");
  var todayDate = new Date(),
    //endDate = new Date( todayDate.getFullYear(), todayDate.getMonth() + 6, todayDate.getDate() +1 );
    date = new Date(dateStr[2], dateStr[0] - 1, dateStr[1]);
  return todayDate > date;
}

// Expects a string like  '4/10/2020'
export function isDueIn5Days(dateStr) {
  var inputDate = new Date(
    dateStr.split("/")[2],
    dateStr.split("/")[1] - 1,
    dateStr.split("/")[0]
  );
  var endDate = new Date();
  var todayDate = new Date();
  endDate.setDate(endDate.getDate() + 5); // adding 5 days from today
  return inputDate < endDate && inputDate > todayDate;
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let newAmount = Number(amount).toFixed(2)
    amount = Math.round(Math.abs(newAmount) * 100) / 100

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}

// Duplicate object
export function duplicate(object) {
  return Object.assign({}, object);
}

// Return empty string if value is null
export function nullToEmptyString(value) {
  return value === null ? "" : value;
}

// Return zero if value is null
export function nullToZero(value) {
  return value === null ? 0 : value;
}

// Add (s) to any string by count
export function plural(value) {
  return value === 1 ? "" : "s";
}

// Check if object is empty
export function isEmpty(obj) {
  let name;
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  if (obj.constructor !== Object) {
    return false;
  }
  return true;
}

export function sanitizeText(text) {
  return (
    text
      .toString()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      //.replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
}

// Slug
export function slug(text) {
  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      //.replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
}

export function CalculateSelectedPropertyTotalCommonsCharge(propertyAdditionalCommonAreaExpenses, propertyResidualAmountsToAdd, totalUnits) {
  let total = 0;
  let kwhTotal = 0;
  propertyAdditionalCommonAreaExpenses.items.map((CommonAreaExpense) => {
    if (CommonAreaExpense.active) {
      total = total + CommonAreaExpense.expenseValue;
    }
  });
  let todayNumerical = new Date();
  let currentMonth = todayNumerical.getMonth() + 1;
  let currentYear = todayNumerical.getFullYear();

  propertyResidualAmountsToAdd.items.map((RollOverItem) => {
    if (RollOverItem.transactions) {
      RollOverItem.transactions.map((transaction) => {
        if (transaction.fiscalMonth == currentMonth && transaction.fiscalYear == currentYear) {
          if (transaction.costUsed != null) {
            total = total + (transaction.costUsed / totalUnits);
          }
          if (transaction.kwhUsed != null) {
            kwhTotal = kwhTotal + (transaction.kwhUsed / totalUnits);
          }
        }
      });
    }
  });
  return total;
}

export function buildRiders(usage, propUtil, originalRiders, isCommonAccount, billDate, unitCount = 1, totalVendorBillUsage =
  1) {
  let riders = [];
  let abwtiToCreate = {};
  let customerCharge = propUtil.utilCustomerCharge;
  let energyCharge = propUtil.utilEnergyCharge * usage;
  let ridersTotal = 0;
  let generationTotal = 0;
  let transmissionTotal = 0;
  let distributionTotal = 0;
  let rate = 0;
  if (!isCommonAccount || usage < 400) {
    for (let j = 0; j < originalRiders.length; j++) {
      let rider = Object.assign({}, originalRiders[j]);
      rider.riderAmount = 0;
      if (moment(billDate) >= moment(rider.startDate) && moment(billDate) <= moment(rider.endDate)) {
        // Handles winter or summer rate to use
        rate = rider.rateWinter;
        rider.effectiveRate = rider.rateWinter;
        if (moment(billDate).format('M') >= 5 && moment(billDate).format('M' <= 8)) {
          rate = rider.rateSummer;
          rider.effectiveRate = rider.rateSummer;
        }
        // Sees if the kwh usage is in range making it applicable for the rider item
        if (rate == 0 || usage < rider.minKwh) {
          rider.riderAmount = 0;
        }
        else {
          let applicableUsage = usage;
          //
          if (usage > rider.minKwh) {
            applicableUsage = applicableUsage - rider.minKwh;
          }
          if (usage > rider.maxKwh) {
            applicableUsage = rider.maxKwh;
            if (rider.minKwh > 0) {
              applicableUsage = applicableUsage - rider.minKwh;
            }
          }

          if (rider.rateBasis == 'kWh') {
            if (rider.rateType == '$') {
              rider.riderAmount = rate * (applicableUsage);
            }
            else { //%
              // if no totla vendor usage passed it assumes all units produced the same amount and divides by unit count
              if (totalVendorBillUsage == -1) {
                rider.riderAmount = rate * (applicableUsage / unitCount);
              }
              else { rider.riderAmount = rate * (applicableUsage / totalVendorBillUsage); }
            }
          }
          else if (rider.rateBasis == 'Flat') {
            if (rider.rateType == '$') {
              rider.riderAmount = rate;
            }
            else {
              rider.riderAmount = (rate / unitCount);
            }
          }
          else if (rider.rateBasis == 'Base') {
            if (rider.rateType == '$')
              rider.riderAmount = rate;
            else
              rider.riderAmount = (rate / 100) * (customerCharge + energyCharge);
          }
        }
      }

      if (rider.riderAmount < 0 && Number(rider.riderAmount.toFixed(2) == 0)) {
        let amount = Math.abs(Number(rider.riderAmount).toFixed(3) * 1000)
        if (amount >= 1) {
          amount = Number(-0.01);
          rider.riderAmount = amount;
        }
        else {
          amount = Number(0)
          rider.riderAmount = amount;
        }
      }
      //rider.riderAmount = rider.riderAmount // this should be rounded towards zero, may be a problem
      rider.riderAmount = Number(rider.riderAmount.toFixed(2));
      ridersTotal += rider.riderAmount;
      if (rider.riderAmount != 0) {
        if (rider.riderType == 'Generation') {
          generationTotal += rider.riderAmount;
        }
        else if (rider.riderType == 'Transmission') {
          transmissionTotal += rider.riderAmount;
        }
        else if (rider.riderType == 'Distribution') {
          distributionTotal += rider.riderAmount;
        }
      }
      riders.push(rider);
    }

    abwtiToCreate.utilRidersTotal = ridersTotal;
    abwtiToCreate.utilDistributionTotal = distributionTotal;
    abwtiToCreate.utilGenerationTotal = generationTotal;
    abwtiToCreate.utilTransmissionTotal = transmissionTotal;
    abwtiToCreate.utilEnergyCharge = energyCharge
    abwtiToCreate.utilCustomerCharge = propUtil.utilCustomerCharge;
    abwtiToCreate.totalCharge = ridersTotal + customerCharge + energyCharge;
    abwtiToCreate.utilRateKWH = 0;
    abwtiToCreate.rate = 0;
    if (usage > 0)
      abwtiToCreate.rate = abwtiToCreate.totalCharge / usage;
  }
  else {
    abwtiToCreate.rate = propUtil.contractRate || 0;
    abwtiToCreate.utilRidersTotal = 0;
    abwtiToCreate.utilDistributionTotal = 0;
    abwtiToCreate.utilTransmissionTotal = 0;
    let energyCharge2 = usage * abwtiToCreate.rate;
    abwtiToCreate.totalCharge = energyCharge2;
    abwtiToCreate.utilRateTotal = abwtiToCreate.totalCharge;

  }

  return { riders, bill: abwtiToCreate };

}
