import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import AdvancedSettings from "./AdvancedSettings";
import DisconnectSettings from "./DisconnectSettings";
import GeneralSettings from "./GeneralSettings";
import NotificationSettings from "./NotificationSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function Settings(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isAdmin = props.user.details.isSuperUser == 1 ? true : false;

  return (
    <div className={classes.root}>
      {!isAdmin && (
        <Typography variant="h4" component="h4">
          You must be an admin in order to update any settings in the Ubil
          application.
        </Typography>
      )}
      <div>
        <Accordion
          disabled={!isAdmin}
          expanded={true}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              General settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <GeneralSettings isAdmin={isAdmin} />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!isAdmin}
          expanded={true}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>Disconnects</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DisconnectSettings settings={props.setting.settings.items} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!isAdmin}
          expanded={true}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>
              Notification settings
            </Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NotificationSettings />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!isAdmin}
          expanded={true}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              Advanced Settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdvancedSettings />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
// Component Properties
Settings.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function SettingsState(state) {
  return {
    setting: state.setting,
  };
}

export default connect(SettingsState, {})(Settings);
