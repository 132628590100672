import { GET_OWNERS, GET_OWNERS_RESPONSE, RESET, GET_OWNER, GET_OWNER_RESPONSE, SET_OWNERS } from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    ownersList: {
      items: [],
    },
    owner: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_OWNERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_OWNERS_RESPONSE:
      data.ownersList.items = action.owners;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case SET_OWNERS:
      return {
        ...state,
        data: {
          ...state.data,
          ownersList: {
            items: action.owners,
          },
        },
      };
    case GET_OWNER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_OWNER_RESPONSE:
      return {
        ...state,
        data: {
          ...state.data,
          owner: action.owner,
        },
        error: action.error,
        isLoading: false,
      };
      default:
      return {
        ...state,
      };
  }
};
