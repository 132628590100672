import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  MenuItem,
  TextField,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  ButtonGroup
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { getAllPropertyPreviews } from "../../apl/api/actions";
import { logout } from "../../user/api/actions";
import {
  getAllBillingDashboardInfo,
  getBillInProgressDraft,
  resetBillingObject,
  updateBillingWizardBillInProgress,
  updateBillingWizardSelectedProperty,
} from "../api/actions";

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));


//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function CurrentBillBatchesManagerTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const navTo = (page) => history.push(page);
  const fieldTitles = ["Property", "Status", "Created"];
  const fieldTypes = ["label", "label", "dateLabel"];
  const fieldNames = ["propertyName", "status", "created"]; // Must be the exact name of the properties you wanna show

  const fieldTitlesMoves = ["Property", "Status", "Type", "Items Count", "Created On", "Created By"];
  const fieldTypesMoves = ["label", "label", "label", "label", "dateLabel", "label"];
  const fieldNamesMoves = ["propertyName", "status", "type", "itemsCount", "created", "creatorName"];

  const [targetProperty, setTargetProperty] = React.useState("");
  const [targetPropertyName, setTargetPropertyName] = React.useState(null);
  const [currentStateFilters, setCurrentStateFilters] = useState((props.isMovesTable && props.isMovesTable == true) ? 'All' : 'In Progress');
  const [currentTypeFilters, setCurrentTypeFilters] = useState((props.isMovesTable && props.isMovesTable == true) ? 'All' : 'Billing');
  const [propByBillingType, setPropByBillingType] = React.useState({items:[], toUpdate:[], toDelete:[]});

  useEffect(() => {
    if (!props.billing.billProgressHistoryIsLoading) {
      const type = props.billing.data.billingWizardDashboard.incompleteBatches.items.filter(property => property.propertyBillingType?.toUpperCase() === props.billingType?.toUpperCase())
      setPropByBillingType(
          {
            ...propByBillingType,
            items: type
          })
    }
  }, [props.billingType, props.billing.billProgressHistoryIsLoading]);

  const handleSelectedPropertyChange = (event) => {
    // recieves the prop uuid
    if (event.target.value == "All") {
      setTargetPropertyName(null);
      setTargetProperty("");
    }
    else {
      var targetIndex = props.properties.data.propertiesList.items.map(function (e) { return e.uuid; }).indexOf(event.target.value);
      if (targetIndex != -1) {
        setTargetPropertyName(props.properties.data.propertiesList.items[targetIndex].name);
        setTargetProperty(event.target.value);
      }
    }
  };


  const onBatchSelected = (item) => {
    props.getBillInProgressDraft(item.wtUUID).then(() => {
      props.updateBillingWizardSelectedProperty(item.property);
      navTo("/billingwizard");
    });
  };
  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container spacing={6}>
        <Grid item lg={8} md={8} sm={8}>
          <>
            <FormControl fullWidth my={2}>
              <InputLabel id="propertyBillBatchDropDownLabel">Location{"  "}
                {props.apl.isLoading &&
                  <CircularProgress size={15} m={0} />
                }
              </InputLabel>
              {targetProperty == "" && props.apl.isLoading ?
                <Select
                  labelId="propertyBillBatchDropDownLabel"
                  id="propertyBillBatchDropDown"
                  value={targetProperty}
                  className={classes.selectedPropertyLocationLabel}
                  onChange={handleSelectedPropertyChange}
                  fullWidth
                  disabled
                  my={2}>
                </Select>
                :
                <Select
                  labelId="propertyBillBatchDropDownLabel"
                  id="propertyBillBatchDropDown"
                  value={targetProperty}
                  className={classes.selectedPropertyLocationLabel}
                  onChange={handleSelectedPropertyChange}
                  fullWidth
                  my={2}>
                  {props.properties.data.propertiesList.items.length > 0 ?
                    [
                      <MenuItem value={"All"}>All</MenuItem>,
                      props.properties.data.propertiesList.items.map((item, key) => {
                        return (
                          <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                        )
                      })
                    ]
                    :
                    [
                      <MenuItem value={-1}>No Properties Found</MenuItem>
                    ]
                  }
                </Select>
              }
            </FormControl>
          </>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
          >
            <Button
              onClick={() => { setCurrentTypeFilters("All") }}
              className={
                currentTypeFilters == "All"
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => { setCurrentTypeFilters("Move Ins") }}
              className={
                currentTypeFilters == "Move Ins"
                  ? classes.active
                  : classes.inactive
              }
            >
              Move Ins
            </Button>
            <Button
              onClick={() => { setCurrentTypeFilters("Move Outs") }}
              className={
                currentTypeFilters == "Move Outs"
                  ? classes.active
                  : classes.inactive
              }
            >
              Move Outs
            </Button>
            <Button
              onClick={() => { setCurrentTypeFilters("Billing") }}
              className={
                currentTypeFilters == "Billing"
                  ? classes.active
                  : classes.inactive
              }
            >
              Billing
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
          >
            <Button
              onClick={() => { setCurrentStateFilters("All") }}
              className={
                currentStateFilters == "All"
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("In Progress") }}
              className={
                currentStateFilters == "In Progress"
                  ? classes.active
                  : classes.inactive
              }
            >
              In Progress
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("Complete") }}
              className={
                currentStateFilters == "Complete"
                  ? classes.active
                  : classes.inactive
              }
            >
              Complete
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid >
    );
  };


  useEffect(() => {
    if (props.isMovesTable && props.isMovesTable == true) {
      props.getAllBillingDashboardInfo(true);
    }
    else {
      props.getAllPropertyPreviews();
    }
  }, [props.user]);

  const onBatchSelect = (item) => {
    navTo(`/batches/${item.id}`);
  };

  const onNewPageBatchSelect = (item) => {
    window.open(`/batches/${item.id}`);
  };


  const createNewDraft = () => {
    props.resetBillingObject();
    navTo("/billingwizard");
  };

  console.log("props.billing.data.billingWizardDashboard.incompleteBatches");
  console.log(props.billing.data.billingWizardDashboard.incompleteBatches);

  const toShowAll = JSON.parse(JSON.stringify(props.billing.data.billingWizardDashboard.incompleteBatches || []));

  // Loops through each item:
  for (var x = 0; x < toShowAll.items.length;) {
    // Filtering by property
    if (targetProperty != "" && targetProperty != null) {
      if (toShowAll.items[x].propertyUuid != targetProperty) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }

    if (currentStateFilters != "All") {
      //&& toShowAll.items[x].processed != 1 && toShowAll.items[x].onHold != 1 && toShowAll.items[x].issues != 1 && toShowAll.items[x].readyForProcess != 1
      if (
        (currentStateFilters == "In Progress" && toShowAll.items[x].status != "In Progress") ||
        (currentStateFilters == "Complete" && toShowAll.items[x].status != "Complete")
      ) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    // Filter Toggles
    if (currentTypeFilters != "All") {
      if (
        ((currentTypeFilters == "Billing" && toShowAll.items[x].type != "Billing") ||
          (currentTypeFilters == "Move Ins" && toShowAll.items[x].type != "Move Ins") ||
          (currentTypeFilters == "Move Outs" && toShowAll.items[x].type != "Move Outs"))
      ) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    x++;
  }

  useEffect(()=>{
    setPropByBillingType(prevState => ({
      ...prevState,
      items: toShowAll?.items?.filter(item => item.propertyBillingType?.toUpperCase() === props.billingType?.toUpperCase())
    }));
  },[targetProperty, currentTypeFilters])

  console.log(props.billing.billProgressHistoryIsLoading);
  if (props.isMovesTable) {
    return (
      <Grid item xs={12}>
        <EditableTable
          // Functional methods of table
          // Properties of table
          isLoading={props.billing.billProgressHistoryIsLoading}
          tableTitle={"Moves Billing Batches"}
          Filters={<Filters />}
          readOnly={true}
          canAddItems={false}
          canSave={false}
          currentActiveFilters={[currentStateFilters, currentTypeFilters, targetPropertyName]}
          objectArray={
            propByBillingType
          }
          fieldTitles={fieldTitlesMoves}
          fieldTypes={fieldTypesMoves}
          handleRowClickParentMethod={onNewPageBatchSelect}
          fieldNames={fieldNamesMoves}
          showButtons={false}
        />
      </Grid>
    );
  }
  else {
    return (
      <Grid item xs={12}>
        <EditableTable
          // Functional methods of table
          // Properties of table
          isLoading={props.billing.billProgressHistoryIsLoading}
          tableTitle={"Billing Batches"}
          Filters={<Filters />}
          readOnly={true}
          canAddItems={false}
          canSave={false}
          currentActiveFilters={[currentStateFilters, currentTypeFilters, targetPropertyName]}
          objectArray={
            propByBillingType
          }
          fieldTitles={fieldTitlesMoves}
          fieldTypes={fieldTypesMoves}
          handleRowClickParentMethod={onBatchSelect}
          fieldNames={fieldNamesMoves}
          showButtons={false}
        />
      </Grid>
    );
  }
}
// Component Properties
CurrentBillBatchesManagerTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
};

// Component State
function CurrentBillBatchesManagerTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    properties: state.properties
  };
}
export default connect(CurrentBillBatchesManagerTableState, {
  logout,
  getAllPropertyPreviews,
  getAllBillingDashboardInfo,
  getBillInProgressDraft,
  updateBillingWizardBillInProgress,
  updateBillingWizardSelectedProperty,
  resetBillingObject,
})(CurrentBillBatchesManagerTable);
