import axios from "axios";
require("../../../setup/config/apiConfig");

export const GET_OWNER_JOURNALS = "GET_OWNER_JOURNALS";
export const GET_OWNER_JOURNALS_RESPONSE = "GET_OWNER_JOURNALS_RESPONSE";
export const GET_OWNER_JOURNALS_RESPONSE_FAIL =
  "GET_OWNER_JOURNALS_RESPONSE_FAIL";

export const UPDATE_OWNER_JOURNALS_FOUND =
  "UPDATE_OWNER_JOURNALS_FOUND";
export function getOwnerJournals(ownerId, page, limit, resetCache = true, loading = true) {

  return async (dispatch) => {
    dispatch({
      type: GET_OWNER_JOURNALS,
      loading
    });
    return axios
    .get(`/owner-journal/${ownerId}`, { params: { page, limit } })
    .then((response) => {
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }
      dispatch(
        updateOwnerJournalsFound(
          response.data,
          resetCache
        ))
      dispatch({
        type: GET_OWNER_JOURNALS_RESPONSE,
        data: response.data.data,
        totalRows: response.data.totalRows,
        error: error
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_OWNER_JOURNALS_RESPONSE_FAIL,
        loading: false,
        error: error
      });
    });
  };
}

export function updateOwnerJournalsFound(
  data,
  resetCache
) {
  return {
    type: UPDATE_OWNER_JOURNALS_FOUND,
    data,
    resetCache,
  };
}