import {GET_OWNER_JOURNALS, GET_OWNER_JOURNALS_RESPONSE, UPDATE_OWNER_JOURNALS_FOUND} from "./actions";

const initialState = {
  loading: false,
  error: null,
  ownerJournalsPreviews: {
    ownerJournals: [],
  },
  data: {
    ownerJournals: {
      items: [],
      toDelete: [],
      toUpdate: []
    },
  },
  totalRows: null,
  searchQuery: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OWNER_JOURNALS:
      return {
        ...state,
        loading: action.loading,
        error: null
      };
    case GET_OWNER_JOURNALS_RESPONSE:
      return {
        ...state,
        loading: action.loading
      };
    case UPDATE_OWNER_JOURNALS_FOUND:
      let newStateData = state.data
      if(action.resetCache){
        newStateData.ownerJournals = {
          items: action.data.data,
          toDelete: [],
          toUpdate: [],
        };
      }else{
        newStateData.ownerJournals = {
          items: newStateData.ownerJournals.items.concat(action.data.data),
          toDelete: [],
          toUpdate: [],
        }
      }
      if (action.totalRows !== null) {
        newStateData.totalRows = action.data.totalRows;
      }
      return {
        ...state,
        data: newStateData,
      };
    default:
      return state;
  }
};
