import React from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import {
  Card,
  CardContent,
  Grid,
  FormGroup,
  Typography,
  Checkbox,
  Select as MuiSelect,
  TextField as MuiTextField,
  Button as MuiButton,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { put, post } from "../api/actions";

import { setSnackbar } from "../../snackbar/api/snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { formatPhoneNumber } from "../../../setup/helpers";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const PropertyManagerForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const handleSelectedAccountStatusChange = (event) => {
    onFieldChange(event);
    //setAccountState(event.target.value);
  };

  const save = () => {
    const pm = props.propertyManagers.data.propertyManager;
    const newPm = {
      id: pm.id,
      uuid: pm.uuid,
      name: pm.name,
      address1: pm.address1,
      address2: pm.address2,
      city: pm.city,
      state: pm.state,
      zip: pm.zip,
      phone: pm.phone,
      statusId: pm.statusId,
      email: pm.email,
      contactName: pm.contactName,
      billingEmail: pm.billingEmail,
      delinquencyEmail: pm.delinquencyEmail,
      stopLateFee: pm.stopLateFee,
      stopSetupFee: pm.stopSetupFee,
      updateAllSubAccounts: true,
      developerId: pm.developerId
    };
    if (!newPm.id) {
      props.post(newPm).then(() => {
        if (props.propertyManagers.postPropertyManagerError) {
          dispatch(
            setSnackbar(true, "error", "Could not create property manager")
          );
        } else {
          dispatch(setSnackbar(true, "success", "Property Manager Created!"));
        }
        props.onUpdate();
      });
    } else {
      props.put(newPm).then((res) => {
        if (props.propertyManagers.putPropertyManagerError) {
          dispatch(
            setSnackbar(true, "error", "Could not update property manager")
          );
        } else {
          dispatch(setSnackbar(true, "success", "Property Manager Updated!"));
        }
        props.onUpdate();
      });
    }
  };

  const onFieldChange = (event) => {
    props.propertyManagers.data.propertyManager[event.target.name] =
      event.target.value;
  };

  const toggleSetupFee = () => {
    setStopSetUpFee(!stopSetupFee);
    props.propertyManagers.data.propertyManager.stopSetupFee = !stopSetupFee;
  };

  const toggleLateFee = () => {
    setStopLateFee(!stopLateFee);
    props.propertyManagers.data.propertyManager.stopLateFee = !stopLateFee;
  };

  // Handles vendor drop down
  const [stopSetupFee, setStopSetUpFee] = React.useState(props.propertyManagers.data.propertyManager?.stopSetupFee == 1 ? true : false);
  const [stopLateFee, setStopLateFee] = React.useState(props.propertyManagers.data.propertyManager?.stopLateFee == 1 ? true : false);
  const [PropertyManagerState, setPropertyManagerState] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  console.log(props)

  if (props.propertyManagers.propertyManagerIsLoading || props.own) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {/* PropertyManager Details */}
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Name"
                type="text"
                onChange={onFieldChange}
                name="name"
                defaultValue={props.propertyManagers.data.propertyManager.name}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Phone"
                type="text"
                onChange={onFieldChange}
                name="phone"
                defaultValue={formatPhoneNumber(props.propertyManagers.data.propertyManager.phone)}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Address 1"
                type="text"
                onChange={onFieldChange}
                name="address1"
                defaultValue={
                  props.propertyManagers.data.propertyManager.address1
                }
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Address 2"
                type="text"
                onChange={onFieldChange}
                name="address2"
                defaultValue={
                  props.propertyManagers.data.propertyManager.address2
                }
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={4} xs={12}>
              <TextField
                label="City"
                type="text"
                onChange={onFieldChange}
                name="city"
                defaultValue={props.propertyManagers.data.propertyManager.city}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="State"
                type="text"
                onChange={onFieldChange}
                name="state"
                defaultValue={props.propertyManagers.data.propertyManager.state}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Zip"
                type="text"
                onChange={onFieldChange}
                name="zip"
                defaultValue={props.propertyManagers.data.propertyManager.zip}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="statusIdLabel">Status</InputLabel>
              <Select
                labelId="statusId"
                id="statusId"
                onChange={onFieldChange}
                defaultValue={props.propertyManagers.data.propertyManager.statusId}
                name="statusId"
              >
                {[props.apl.genericTypes.propertyManagerStatus.data.map(
                  (item, index) => (
                    <MenuItem id="AccountStatusOption1" value={item.id}>
                      {item.description}
                    </MenuItem>
                  )
                )]}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="Contact Name"
              type="text"
              onChange={onFieldChange}
              name="contactName"
              defaultValue={props.propertyManagers.data.propertyManager.contactName}
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="Email"
              type="text"
              onChange={onFieldChange}
              name="email"
              defaultValue={props.propertyManagers.data.propertyManager.email}
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="Billing Email"
              type="text"
              onChange={onFieldChange}
              name="billingEmail"
              defaultValue={props.propertyManagers.data.propertyManager.billingEmail}
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="Delinquency Email"
              type="text"
              onChange={onFieldChange}
              name="delinquencyEmail"
              defaultValue={props.propertyManagers.data.propertyManager.delinquencyEmail}
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="developerIdLabel">Developer</InputLabel>
              <Select
                labelId="developerId"
                id="developerId"
                onChange={onFieldChange}
                defaultValue={props.propertyManagers.data.propertyManager.developerId}
                name="developerId"
              >
                {[props.developers?.data?.developersList?.items?.map(
                  (item, index) => (
                    <MenuItem id="DeveloperOption" value={item.id}>
                      {item.name}
                    </MenuItem>
                  )
                )]}
              </Select>
            </FormControl>
          </Grid>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stopSetupFee}
                  onChange={toggleSetupFee}
                  name="stopSetupFee"
                />
              }
              label="Stop Setup Fee"
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stopLateFee}
                  onChange={toggleLateFee}
                  name="stopLateFee"
                />
              }
              label="Stop Late Fee"
            />
          </FormGroup>
          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PropertyManagerForm.propTypes = {
  propertyManagers: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  propertyManagerStatuses: PropTypes.object.isRequired,
  put: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  developers: PropTypes.object.isRequired
};

function PropertyManagerFormState(state) {
  return {
    propertyManagers: state.propertyManagers,
    owners: state.owners,
    propertyManagerStatuses: state.propertyManagerStatuses,
    apl: state.apl,
    developers: state.developers
  };
}
export default connect(PropertyManagerFormState, { put, post })(
  PropertyManagerForm
);
