import React, {useEffect} from "react";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import {
    Paper,
    Card,
    CardContent,
    Divider as MuiDivider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import {connect, useDispatch, useSelector} from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import style from "./styling/style";
import {useLocation} from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

function DetailCard(props) {
    const owner = useSelector(state => state.owners.data.owner);
    const useStyles = style;
    const classes = useStyles();

    return (
        <Card mb={6}>
            <CardContent xs={12}>
                <Grid container xs={12}>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                        <Typography variant="h6" gutterBottom>
                            Owner
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className="mb-2 mt-2" my={1}/>
                {!owner ? (
                    <>
                        <Skeleton
                            variant="rect"
                            height="40px"
                            style={{
                                backgroundColor: `#bdbfbe`,
                                padding: 6,
                                margin: 6,
                                marginBottom: 3,
                            }}
                        />
                        <Skeleton
                            variant="rect"
                            height="40px"
                            style={{
                                backgroundColor: `#bdbfbe`,
                                padding: 6,
                                margin: 6,
                                marginBottom: 3,
                            }}
                        />
                        <Skeleton
                            variant="rect"
                            height="40px"
                            style={{
                                backgroundColor: `#bdbfbe`,
                                padding: 6,
                                margin: 6,
                                marginBottom: 3,
                            }}
                        />
                        <Skeleton
                            variant="rect"
                            height="40px"
                            style={{
                                backgroundColor: `#bdbfbe`,
                                padding: 6,
                                margin: 6,
                                marginBottom: 3,
                            }}
                        />
                    </>
                ) : (
                    <Grid
                        container
                        spacing={2}
                        lg={12}
                        className={classes.PropertyInfoGrid}
                    >
                        <Grid item xs={12} lg={12} spacing={2}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Name </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.name ||
                                                        "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Contact Name </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.contactName ||
                                                        "N/A"}{" "}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Status </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.active === 1 ? 'Active': 'Inactive' || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Address </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.address1 || "N/A"}{" "}
                                                    <br/>
                                                    {owner?.address2 || ""}{" "}
                                                    <br/>
                                                    {owner?.city || ""}{" "}
                                                    {owner?.state || ""}
                                                    {", "}{owner?.zip || ""}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Phone </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.phone || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Email </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.email || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Mailing Address </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.mailingAddress1 || "N/A"}{" "}
                                                    <br/>
                                                    {owner?.mailingAddress2 || ""}{" "}
                                                    <br/>
                                                    {owner?.mailingCity || ""}{" "}
                                                    {owner?.mailingState || ""}
                                                    {", "}{owner?.mailingZip || ""}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Mailing Email </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.mailingEmail || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Account ID </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {owner?.glCode || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1"> Updated </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {new Date(
                                                        owner?.updated
                                                    ).toLocaleDateString() || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
}


export default DetailCard;
