// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_OWNERS = "GET_OWNERS";
export const GET_OWNERS_RESPONSE = "GET_OWNERS_RESPONSE";
export const GET_OWNERS_RESPONSE_FAIL = "GET_OWNERS_RESPONSE_FAIL";
export const GET_OWNER = "GET_OWNER";
export const GET_OWNER_RESPONSE = "GET_OWNER_RESPONSE";
export const GET_OWNER_RESPONSE_FAIL = "GET_OWNER_RESPONSE_FAIL";
export const SET_OWNERS = 'SET_OWNERS';

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNERS,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner`)
      .then((response) => {
        dispatch({
          type: GET_OWNERS_RESPONSE,
          owners: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNERS_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNER,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner/${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_OWNER_RESPONSE,
          owner: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getById(id, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNER,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner-id/${id}`)
      .then((response) => {
        dispatch({
          type: GET_OWNER_RESPONSE,
          owner: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export const setOwners = (owners) => ({
  type: SET_OWNERS,
  owners,
});