import React from 'react';
import {Card} from "@material-ui/core";
import {EditableTable} from "../../../../components/EditableTable";
import {useSelector} from "react-redux";
import moment from "moment";

const WorkTable = (props) => {

  const fieldTitles = ["Property", " Billing Type", "Creator", "Bill Date", "Created On", "Updated On", ""];
  const fieldTypes = ["label", "label", "label", "dateLabel", "dateLabel","dateLabel", "editBtn"];
  const fieldNames = ["propertyName", "propertyBillingType", "userName", "billDate", "created", "updated", ""];

  const workTablesThisMonth = useSelector(state => {
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const filteredItems = state.billing.data.billingWizardDashboard.incompleteWorkTables.items.filter(item => {
      const createdDate = moment(item.created);
      return (createdDate.month() === currentMonth) && (createdDate.year() === currentYear) && (item.propertyBillingType !== 'BILL');
    });

    return filteredItems;
  });

  const isLoading = useSelector(state => state.billing.billProgressHistoryIsLoading)

  return (
    <Card mb={6} elevation={3}>
          <EditableTable
            isLoading={isLoading}
            tableTitle={"Created Work Tables"}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            objectArray={{items: workTablesThisMonth, toDelete: [], toUpdate: []}}
            canSave={false}
            newItemCreationMethod={props.createWorkTable}
            handlePreviewClickParentMethod={props.editWorkTable}
            readOnly={true}
          />
    </Card>
  );
};

export default WorkTable;